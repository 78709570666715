import {decrypt } from "@/utils/aesUtil.js";
export default {
	data() {
		return {
			loading: true,

			getPatient: {
				userType: Number,
				uuid: 'string',
				account: '',
				deviceType: '',
				createTimes: [],
				// 创建日期开始
				startCreateTime: null,
				// 创建日期结束
				endCreateTime: null,
				page: 1,
				size: 10
			},
			tableData: [],
			operateList: [],
			total: 0,
			//关联医生
			doctorShow: Number,
			//禁用按钮
			statusShow: Number,
			//cgm 分享患者uuid
			ufu: null,
			//申请查看
			applyForCheck: false,
			patientname: null,

			dialogPumpFlag: false,
			pumpData: [],
			innerVisible: false
		}
	},
	mounted() {
		
		this.ufu = this.$route.query.ufu;
		console.log(this.ufu)
		this.getConditionFromCache();
		//申请查看
		this.getapplyForCheck();
		
		this.getPatientList();
		this.roleShow();
	},
	destroyed() {
		sessionStorage.removeItem("detail");
	},
	methods: {
		getConditionFromCache() {
			console.log(sessionStorage.getItem('detail'))
			if (sessionStorage.getItem('detail')) {
				this.getPatient.page = Number(sessionStorage.getItem("currPage")) > 0 ? Number(sessionStorage.getItem("currPage")) : 1;
				this.getPatient.size = Number(sessionStorage.getItem("size")) > 0 ? Number(sessionStorage.getItem("size")) : 10;

				// account,deviceType,startCreateTime,endCreateTime
				let searchConditionStr = sessionStorage.getItem("serCondition");
				let searchCondition = JSON.parse(searchConditionStr);
				if(searchCondition){
					this.getPatient.account = searchCondition.account;
					this.getPatient.deviceType = searchCondition.deviceType;
					this.getPatient.startCreateTime = searchCondition.startCreateTime;
					this.getPatient.endCreateTime = searchCondition.endCreateTime;
				}
			} else {
				this.getPatient.page = 1;
				this.getPatient.size = 10;

				sessionStorage.removeItem("currPage");
				sessionStorage.removeItem("size");
			}
		},
		//申请查看显示隐藏
		getapplyForCheck() {
			console.log(this.ufu)
			if (this.ufu !== '' || this.ufu !== null) {
				this.$axios
					.get("/api/sys/web/user/encrypt/detail/" + this.ufu)
					.then(response => {
						if (response.data.code === 200) {
							var popped = sessionStorage.getItem('popped')
							if (popped == 1) {
								this.applyForCheck = false
							} else {
								this.applyForCheck = true
							}
							console.log(popped)
							if (response.data.data.name !== null) {
								this.patientname = decrypt(response.data.data.name);
							} else {
								this.patientname = decrypt(response.data.data.account);
							}
						}
					})
			}
		},
		//申请查看功能
		doapplyForCheck() {
			var params = {
				userFollowUpUuid: sessionStorage.getItem('userUuid'),
				userUuid: this.ufu,
			}
			this.$axios({
				method: "post",
				url: "/api/cgm/web/cgmPatientFollowUp/applyFor", // 接口地址
				data: params
			}).then(res => {
				if (res.data.code === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
					});
					this.getPatientList();
					this.userFollowUpUuid = null;
					this.applyForCheck = false;
					sessionStorage.setItem("popped", 1);
				} else if (res.data.code === 500) {
					this.$message({
						message: "正在审核",
						type: "success",
					});
					this.userFollowUpUuid = null;
					this.applyForCheck = false;
					sessionStorage.setItem("popped", 1);
				} else {
					this.$message({
						message: "操作失败",
						type: "error",
					});
				}
			}).catch(error => {
				console.log(error);
				//this.$message.error("请求失败");
			});
		},
		//根据角色显示隐藏列表功能
		//用户类型：1管理员；2医生；3患者
		roleShow() {
			const userType = sessionStorage.getItem('userType');
			if (userType === '1') {
				this.doctorShow = true;
				this.statusShow = true;
			} else {
				this.doctorShow = false;
				this.statusShow = false;
			}
		},
		//垂体泵按钮带参跳转页面
		doPituitary(uuid) {
			console.log(uuid);
			this.$router.push({
				path: 'patient-detail',
				query: {
					getPatientDetailUuid: uuid,
					userType: 1,
					activeName: 'first'
				}
			});
		},
		//CGM 按钮跳转页面
		doCgmReport(uuid) {
			this.$router.push({
				path: 'patient-cgm-report',
				query: {
					getPatientDetailUuid: uuid,
					userType: 1,
					activeName: 'third'
				}
			});
		},
		//胰岛素泵按钮带参跳转页面
		doInsulin(uuid) {
			this.$router.push({
				path: 'patient-detail',
				query: {
					getPatientDetailUuid: uuid,
					userType: 1,
					activeName: 'second'
				}
			});
		},
		//CGM血糖仪钮带参跳转页面
		doGlucometer(uuid) {
			//console.log(uuid);
			this.$router.push({
				path: 'patient-detail',
				query: {
					getPatientDetailUuid: uuid,
					userType: 1,
					activeName: 'third'
				}
			});
		},
		//关联医生跳转页面
		doRelevanceDoctor(uuid) {
			//console.log(uuid)
			this.$router.push({
				path: 'relevance-doctor',
				query: {
					getDoctorManageUuid: uuid,
				}
			});
		},
		// 查看绑定垂体泵或胰岛素泵
		doPumpDetail(uuid) {
			this.dialogPumpFlag = true;
			this.pumpData = [];
			console.log(uuid)
			this.$axios.get('/api/cgm/web/patientDevice/findCtbOrYdsb/' + uuid).then(res => {
				if (res.data.code === 200) {
					this.pumpData = res.data.data;
				}
			});
			console.log(this.pumpData)
		},
		doUnbind(uuid) {
			this.$confirm("您确定将此用户的泵解绑吗?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				lockScroll: false,
				type: "warning"
			}).then(() => {
				console.log(uuid);
				let param = {
					uuid: uuid
				};
				this.$axios({
					method: "post",
					url: "/api/cgm/web/patientDevice/unbind", // 接口地址
					data: param
				}).then(res => {
					console.log(res);
					if (res.data.code === 200) {
						this.innerVisible = true;
					}
				});
			});

		},
		doClosePop() {
			this.dialogPumpFlag = false;
			this.innerVisible = false;
		},
		//禁用用户
		doForbidden(uuid) {
			this.$confirm("您确定删除吗?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				lockScroll: false,
				type: "warning"
			}).then(() => {
				this.$axios.get('/api/sys/web/user/del/' + uuid).then(res => {
					if (res.data.code === 200) {
						this.getPatientList();
					}
					this.$message.success({
						message: res.data.message,
					});
				}).catch(error => {
					console.log(error);
					//this.$message.error("请求失败");
				});
			}).catch(() => {});
		},
		//启用用户
		dostartUsing(uuid) {
			this.$confirm("您确定启用吗?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				lockScroll: false,
				type: "warning"
			}).then(() => {
				this.$axios.get('/api/sys/web/user/status/' + uuid + "/" + 1).then(res => {
					if (res.data.code === 200) {
						this.getPatientList();
					}
					this.$message.success({
						message: res.data.message,
					});
				}).catch(error => {
					console.log(error);
					//this.$message.error("请求失败");
				});
			}).catch(() => {});
		},
		// 监听 pagesize 改变的事件
		handleSizeChange(newSize) {
			this.getPatient.size = newSize;
			this.getPatientList();
			sessionStorage.setItem('size', newSize);
			sessionStorage.setItem('currPage', this.getPatient.page);
		},
		//监听 页码值 改变的事件
		handleCurrentChange(newPage) {
			this.getPatient.page = newPage;
			this.getPatientList();

			sessionStorage.setItem('currPage', newPage);
			sessionStorage.setItem('size', this.getPatient.size);
		},
		doGetPatientSearch() {
			this.tableData.splice(0, this.tableData.length);
			this.getPatient.page = 1;
			if (this.getPatient.createTimes !== null && this.getPatient.createTimes.length > 1) {
				this.getPatient.startCreateTime = this.getPatient.createTimes[0] + ' 00:00:00';
				this.getPatient.endCreateTime = this.getPatient.createTimes[1] + ' 23:59:59';
			} else {
				this.getPatient.startCreateTime = '';
				this.getPatient.endCreateTime = '';
			}

			let searchCondition = {
				account: this.getPatient.account,
				deviceType: this.getPatient.deviceType,
				startCreateTime: this.getPatient.startCreateTime,
				endCreateTime: this.getPatient.endCreateTime
			};
			sessionStorage.setItem("serCondition", JSON.stringify(searchCondition));

			this.getPatientList();
		},
		/*获取患者列表数据*/
		getPatientList() {
			this.tableData.splice(0, this.tableData.length); //清空数组
			this.getPatient.uuid = sessionStorage.getItem('userUuid');
			this.getPatient.userType = sessionStorage.getItem('userType');
			//this.getPatient.startCreateTime = param.startCreateTime;
			//this.getPatient.endCreateTime = param.endCreateTime;
			console.log("this.getPatient", this.getPatient);
			this.$axios({
				method: "post",
				url: "/api/sys/web/user/encrypt/page", // 接口地址
				data: this.getPatient
			}).then(res => {
				if (res.data.code === 200) {
					this.tableData = res.data.data;
					for(var i=0;i<this.tableData.length;i++){
						var account=this.tableData[i].account;
						if(account){
							account=decrypt(account);
							this.tableData[i].account= account;
						}
						var mobile = this.tableData[i].mobile;
						if(mobile){
							mobile=decrypt(mobile);
							mobile = mobile.substring(0,3) + "****" + mobile.substring(7,12);
							this.tableData[i].mobile= mobile;
						}
					}
					console.log("this.tableData", this.tableData)
					this.totalPages = res.data.pageData.totalPages;
					this.total = res.data.pageData.totalElements;
					this.$refs.pagination.internalCurrentPage = this.getPatient.page;
					this.loading = false;
					// console.log(this.tableData)
				} else {
					this.$message({
						message: "操作失败",
						type: "error",
					});
				}
			}).catch(error => {
				console.log(error);
				//this.$message.error("请求失败");
			});
		},
		//判断连接的什么泵
		/* checkCGMDeviceType(row, typ) {
			console.log(row)
			console.log(typ)
			let ret = false;
			if (typ === 3) {
				// cgm
				for (let i = 0; i < row.deviceList.length; i++) {
					if (row.deviceList[i].deviceType === 3 || row.deviceList[i].deviceType === 4) {
						ret = true;
						break;
					}
				}
			} else if (typ === 1) {
				// 垂体泵
				for (let i = 0; i < row.deviceList.length; i++) {
					if (row.deviceList[i].deviceType === 1) {
						ret = true;
						break;
					}
				}
			} else if (typ === 2) {
				// 胰岛素泵
				for (let i = 0; i < row.deviceList.length; i++) {
					if (row.deviceList[i].deviceType === 2) {
						ret = true;
						break;
					}
				}
			}
			return ret;
		} */
	}
}
